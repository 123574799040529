<template lang="pug">
.campaign-bulk-operation.sticky-top(v-show="selectedCount > 0")
  .container-fluid.h-100
    .row.h-100
      .col-4.d-flex.align-items-center
        slot(name="selectedStr")
          .mr-3 {{ `${$tc('campaignSelected', selectedCount, { count: selectedCount })}` }}
        .form-check
          input#selectAll.form-check-input(type="checkbox" v-model="allSelect")
          label.form-check-label.text-white(for="selectAll") {{ $t('selectAll') }}
      .col-8.d-flex.align-items-center.justify-content-end
        template(v-if="$route.name === 'campaigns'")
          .bulk-operation.mr-5(v-if="isArchive" @click="$emit('archive', itemIds)")
            BulkArchiveSvg
            span.ml-2 {{ $t('archive') }}
          .bulk-operation.mr-5(v-if="isRestore" @click="$emit('restore', itemIds)")
            ArrowClockwise(color="white")
            span.ml-2 {{ $t('restore') }}
          .bulk-operation.mr-5(v-if="canDuplicate" @click="$emit('duplicate', itemIds[0])")
            BulkDuplicateSvg
            span.ml-2 {{ $t('duplicate') }}
          .only-second-version-copy.px-3.mx-3(
            v-else-if="!isSelectedCampaignNew && selectedCount === 1"
          ) {{ $t('canCopyOnlySecondVersion') }}
        .bulk-operation(@click="$emit('delete', itemIds)")
          BulkTrashSvg
          span.ml-2 {{ $t('delete') }}
</template>

<script>
  import BulkDuplicateSvg from '@/assets/admin/svg/BulkDuplicateSvg.vue';
  import BulkArchiveSvg from '@/assets/admin/svg/BulkArchiveSvg.vue';
  import BulkTrashSvg from '@/assets/admin/svg/BulkTrashSvg.vue';
  import ArrowClockwise from '@/assets/admin/svg/ArrowClockwise.vue';
  import { mapGetters } from 'vuex';

  export default {
    components: { BulkDuplicateSvg, BulkTrashSvg, BulkArchiveSvg, ArrowClockwise },
    props: {
      items: { type: Array, required: true },
      isSelectedCampaignNew: { type: Boolean, default: false },
      isArchive: { type: Boolean, default: false },
      isRestore: { type: Boolean, default: false },
    },

    data() {
      return {
        allSelect: false,
      };
    },

    computed: {
      ...mapGetters(['accountFeatures']),
      selectedCount() {
        return this.items.length;
      },

      itemIds() {
        return this.items.map((i) => i._id);
      },

      canDuplicate() {
        return this.isSelectedCampaignNew && this.selectedCount === 1;
      },
    },

    watch: {
      allSelect(v) {
        this.$bus.$emit('allSelectTableBulk', v);
      },
    },

    mounted() {
      this.$bus.$on('allSelectTable', (v) => {
        this.allSelect = v;
      });
    },
  };
</script>

<style lang="sass" scoped>
  .bulk-operation-archive, .bulk-operation-restore
    margin-right: 23px
</style>
