<template lang="pug">
om-modal(
  name="archive-campaign-in-experiment"
  @beforeOpen="beforeOpen"
  headerBorder
  :width="425"
  color="light"
)
  template.border-bottom(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('experiments.modal.archive.title') }}
    .brand-modal-action-icon.cursor-pointer(
      @click="$modal.hide('archive-campaign-from-experiment')"
    )
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    om-body-text(bt400md)
      span(v-html="$tc('experiments.modal.archive.description', campaigns.length)")
  template(slot="modal-footer")
    .d-flex.align-items-center.justify-content-end
      om-button#archive-campaign-in-experiment-cancel.mr-3(secondary @click="skip") {{ $t('experiments.modal.archive.cancel') }}
      om-button#archive-campaign-in-experiment-ok(primary @click="done") {{ $t('experiments.modal.archive.ok') }}
</template>
<script>
  export default {
    data() {
      return {
        campaigns: [],
      };
    },
    methods: {
      done() {
        this.$emit('experiments:archive-campaign-in-experiment', { campaigns: this.campaigns });
        this.$modal.hide('archive-campaign-in-experiment');
      },
      skip() {
        this.$modal.hide('archive-campaign-in-experiment');
      },
      beforeOpen(event) {
        const { campaigns } = event.params;
        this.campaigns = campaigns;
      },
    },
  };
</script>
