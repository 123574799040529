<template lang="pug">
svg(
  :width="width"
  :height="height"
  viewBox="0 0 16 12"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
)
  path(
    d="M14.7099 1.20998C14.617 1.11625 14.5064 1.04186 14.3845 0.991091C14.2627 0.940323 14.132 0.914185 13.9999 0.914185C13.8679 0.914185 13.7372 0.940323 13.6154 0.991091C13.4935 1.04186 13.3829 1.11625 13.29 1.20998L5.83995 8.66998L2.70995 5.52998C2.61343 5.43674 2.49949 5.36343 2.37463 5.31423C2.24978 5.26502 2.11645 5.24089 1.98227 5.24321C1.84809 5.24553 1.71568 5.27426 1.5926 5.32776C1.46953 5.38125 1.35819 5.45846 1.26495 5.55498C1.17171 5.6515 1.0984 5.76545 1.04919 5.8903C0.999989 6.01516 0.975859 6.14848 0.97818 6.28266C0.980502 6.41684 1.00923 6.54925 1.06272 6.67233C1.11622 6.79541 1.19343 6.90674 1.28995 6.99998L5.12995 10.84C5.22291 10.9337 5.33351 11.0081 5.45537 11.0589C5.57723 11.1096 5.70794 11.1358 5.83995 11.1358C5.97196 11.1358 6.10267 11.1096 6.22453 11.0589C6.34639 11.0081 6.45699 10.9337 6.54995 10.84L14.7099 2.67998C14.8115 2.58634 14.8925 2.47269 14.9479 2.34619C15.0033 2.21969 15.0319 2.08308 15.0319 1.94498C15.0319 1.80688 15.0033 1.67028 14.9479 1.54378C14.8925 1.41728 14.8115 1.30363 14.7099 1.20998Z"
    :fill="color"
  )
</template>

<script>
  export default {
    props: {
      width: {
        type: [Number, String],
        default: 10,
      },
      height: {
        type: [Number, String],
        default: 10,
      },
      color: {
        type: String,
        default: '#8F97A4',
      },
    },
  };
</script>
