<template lang="pug">
.empty-state(:class="{ 'with-background': showBackground }")
  .horizontal-alignment.pl-5.py-3.d-flex(v-if="isHorizontal")
    .left-col.pr-5.my-auto(:class="splitClass.left")
      om-heading.pr-2.pb-5(h2 v-if="text1" v-html="$t(text1)")
      om-body-text.pr-5.mb-5.mr-3(bt400ld v-if="text2" v-html="$t(text2)")
      slot(name="underText")
    .d-flex.align-items-center.pl-1(:class="splitClass.right")
      img(:src="imageUrl")
  .vertical-alignment(v-else)
    .col-12.text-center
      img.d-inline-flex.my-5(:src="imageUrl")
      om-heading(h3 v-if="text1" v-html="$t(text1)")
</template>

<script>
  const ALIGNMENTS = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal',
  };
  const SPLIT_RATIO = {
    HALF: 'half',
    THIRD: 'third',
  };

  export default {
    props: {
      type: {
        default: ALIGNMENTS.HORIZONTAL,
        type: String,
      },
      showBackground: {
        type: Boolean,
        default: true,
      },
      text1: {
        type: String,
      },
      text2: {
        type: String,
      },
      imageUrl: {
        type: String,
      },
      splitRatio: {
        type: String,
        default: SPLIT_RATIO.THIRD,
      },
    },
    computed: {
      splitClass() {
        return this.splitRatio === SPLIT_RATIO.THIRD
          ? {
              left: 'col-6 col-xxl-4',
              right: 'col',
            }
          : {
              left: 'col-6',
              right: 'col',
            };
      },
      isHorizontal() {
        return this.type === ALIGNMENTS.HORIZONTAL;
      },
    },
    mounted() {
      this.$emit('show');
    },
  };
</script>

<style scoped lang="sass">
  .left-col
    align-content: center

  .with-background
    background: url('../assets/empty-state/background.svg') no-repeat
    background-size: cover
    border-radius: 12px
</style>
