<template lang="pug">
.d-flex.align-content-center.campaign-alert-popover
  om-tooltip
    .campaign-alert-content
      .campaign-alert-errors(v-if="errors")
        ul.list-group.list-unstyled
          li(v-for="item in errors") {{ $t(`campaignAlerts.${getAlertTypeWithV3(item)}.title`) }}
      .campaign-alert-warnings(v-if="warnings")
        ul.list-group.list-unstyled
          li(v-for="item in warnings") {{ $t(`campaignAlerts.${getAlertTypeWithV3(item)}.title`) }}
    template(slot="trigger")
      uil-exclamation-triangle.cursor-pointer(v-if="errors.length" size="1.5rem" color="#E4252D")
      uil-exclamation-circle.cursor-pointer(v-else size="1.5rem" color="#F0C920")
</template>
<script>
  import { UilExclamationTriangle, UilExclamationCircle } from '@iconscout/vue-unicons';
  import { campaignAlertByTypes } from '@/utils/campaignAlerts';
  import userAlert from '@/mixins/userAlert';

  export default {
    components: { UilExclamationTriangle, UilExclamationCircle },
    mixins: [userAlert],
    props: {
      alerts: {
        type: Array,
      },
      domain: {
        type: String,
      },
    },
    data() {
      return {
        campaignAlertByTypes,
      };
    },
    computed: {
      errors() {
        return this.getAlertsByType('error');
      },
      warnings() {
        return this.getAlertsByType('warning');
      },
    },
    methods: {
      getAlertsByType(type) {
        const items = [];
        this.alerts.forEach((element) => {
          if (campaignAlertByTypes[type].includes(element.type)) {
            items.push(element.type);
          }
        });
        return items;
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  .campaign-alert
    &-content li
      padding: 0.25rem 0 .25rem 1rem
      font-size: 0.85rem
      position: relative
    &-content li::before
      content: ""
      font-weight: bold
      display: inline-block
      width: 8px
      height: 8px
      position: absolute
      margin-left: -1rem
      margin-top: 0.5em
      border-radius: 100%
    &-errors li::before
      background: $om-alert-red-500
    &-warnings li::before
      background: $om-ds-yellow
</style>
