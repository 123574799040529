<template lang="pug">
om-modal(name="campaign-rename" width="420px" @beforeOpen="beforeOpen" headerBorder)
  template(#modal-header)
    .row
      .col
        om-heading(h5) {{ $t('rename') }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide('campaign-rename')" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12")
  template(#modal-body)
    input#campaignNameInput.form-control.form-control-lg(
      @keyup.enter="update"
      :class="{ 'is-invalid': $v.campaignName.$error }"
      type="text"
      :placeholder="$t('newNamePlaceholder')"
      v-model.trim="campaignName"
      :aria-label="$t('newNamePlaceholder')"
    )
  template(#modal-footer)
    .d-flex.justify-content-end
      om-button(primary @click="update") {{ $t('save') }}
</template>

<script>
  import { minLength, required } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        campaignId: null,
        campaignName: '',
      };
    },

    validations: {
      campaignName: {
        required,
        minLength: minLength(1),
      },
    },

    methods: {
      beforeOpen(event) {
        this.campaignId = event.params.campaignId;
        this.campaignName = event.params.campaignName;
      },
      async update() {
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }

        this.$emit('update-campaign-name', {
          campaignId: this.campaignId,
          campaignName: this.campaignName,
        });
        this.$modal.hide('campaign-rename');
      },
    },
  };
</script>
